export function isEmailValid(email: string): boolean {
    const regex =
        /^(?:[a-z0-9!#$%&*+/'`~=?^_{|}-]+(?:\.[a-z0-9!#$%&*+/'`~=?^_{|}-]+)*)@(?:(?:[a-z0-9]+\.)+[a-z0-9]{2,20})$/i;

    return regex.test(email) && email.length <= 128;
}

export function isPasswordValid(password: string): boolean {
    return password.length >= 8 && password.length <= 128;
}

export function isPasswordFormatValid(password: string): boolean {
    const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/;

    return regex.test(password);
}
