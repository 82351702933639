import { useAuthorizeDevice } from '../../api/hooks/useAuthorizeDevice';
import { useAuth } from '../../contexts/AuthContext';
import { Spinner } from '../Spinner/Spinner';
import useTranslate from '../../translation/i18n';
import useChangeTitle from '../../api/hooks/useChangeTitle';

export const DeviceActivationStatus = () => {
    const { t } = useTranslate();
    const { sessionToken, deviceCode } = useAuth();

    if (!sessionToken || !deviceCode)
        throw new Error(t('verification.instructions'));

    const { isLoading, error } = useAuthorizeDevice(sessionToken, deviceCode);

    if (isLoading) return <Spinner />;

    if (error) return <DeviceActivationStatusFailed />;

    return <DeviceActivationStatusSuccess />;
};

export const DeviceActivationStatusFailed = () => {
    const { t } = useTranslate();

    const title = t('activation.title.negative');
    useChangeTitle(title);

    return (
        <div id="device-is-not-activated" className="device-is-not-activated">
            <div
                id="device-is-not-activated-overlay"
                className="device-is-not-activated-overlay"
            >
                <div className="row icon">
                    <img
                        alt="status-failed"
                        data-testid="error-image"
                        src="/images/status/issue.png"
                    />
                </div>
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row">
                    <span>{t('activation.error.instructions')}</span>
                </div>
            </div>
        </div>
    );
};

export const DeviceActivationStatusSuccess = () => {
    const { t } = useTranslate();

    const title = t('activation.title.positive');
    useChangeTitle(title);

    return (
        <div id="device-is-activated" className="device-is-activated">
            <div
                id="device-is-activated-overlay"
                className="device-is-activated-overlay"
            >
                <div className="row icon">
                    <img
                        alt="status-ok"
                        data-testid="success-image"
                        src="/images/status/ok.png"
                    />
                </div>
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <p>
                        <span>{t('activation.description.additional')}</span>
                        <br />
                        <span>{t('activation.description.close_window')}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};
