import { api } from '../utils/axios';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import log from 'loglevel';

export const useSendVerificationEmail = () => {
    const {
        mutate: sendVerificationEmail,
        isLoading,
        error,
    } = useMutation<void, AxiosError, string>(async (userId) => {
        const response = await api.put(
            `/users/${userId}/resend_activation_email`,
        );

        log.info('Resend activation email response: ');
        log.info(response);
    });
    return { sendVerificationEmail, isLoading, error };
};
