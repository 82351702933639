import { api } from '../utils/axios';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import log from 'loglevel';

export const useResetPassword = () => {
    const { mutate: resetPassword, isLoading } = useMutation<
        void,
        AxiosError,
        string
    >(async (email) => {
        const response = await api.post(
            '/users/reset_password',
            {},
            {
                params: { email },
            },
        );

        log.info('Reset password response: ');
        log.info(response);
    });
    return { resetPassword, isLoading };
};
