export const LOCATION = 'WebApp';
export const ERROR_MESSAGE =
    'If you don’t have a PHRTF yet, get your own with the Capture app.';
export const FILE_EXTENSION = '.personalized_headphone';
export const TEST_USER_PREFIX = 'TESTUSER_';

export const enum TelemetryEventNames {
    SIGN_IN = 'SignIn',
    HRTF_DOWNLOAD = 'HRTFDownload',
    BUTTON_CLICK = 'ButtonClick',
    USER_STATUS = 'UserStatus',
}

export const enum CustomerTypes {
    MUSIC = 'Music',
    GAMING = 'Gaming',
    CONSUMER = 'Consumer',
}

export const enum UserProfiles {
    PROF_MUSICIAN = 'PROF_MUSICIAN',
    PROF_GAMER = 'PROF_GAMER',
    CONSUMER = 'CONSUMER',
}

export const enum OktaServerResponses {
    SUCCESS = 'Success',
    UNKNOWN = 'Unknown',
}

export const enum TelemetryServerResponses {
    NONE = 'None',
    SUCCESS = 'Success',
    CONNECTIONFAILURE = 'ConnectionFailure',
    AUTHORIZATIONFAILURE = 'AuthorizationFailure',
    UNKNOWNUSER = 'UnknownUser',
    NOTCAPTURED = 'NotCaptured',
    STILLPROCESSING = 'StillProcessing',
    NEEDSRECAPTURE = 'NeedsRecapture',
    SERVERERROR = 'ServerError',
    UNSUPPORTEDACCOUNT = 'UnsupportedAccount',
}

export const enum OktaUserStatuses {
    INACTIVE = 'Inactive',
    ACTIVE = 'Active',
    UNKNOWN = 'Unknown',
    NONE = 'None',
}

export const enum UserFlows {
    WEBAPP = 'Webapp',
    XBOX = 'Xbox',
}
