import useChangeTitle from '../../api/hooks/useChangeTitle';
import useTranslate from '../../translation/i18n';

export function ChangePasswordConfirmation() {
    const { t } = useTranslate();

    const title = t('change_password.title.confirmation');
    useChangeTitle(title);

    return (
        <div id="password-updated" className="password-updated">
            <div
                id="password-updated-overlay"
                className="password-updated-overlay"
            >
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <p>{t('change_password.description.confirmation')}</p>
                    <p>{t('activation.description.close_window')}</p>
                </div>
            </div>
        </div>
    );
}
