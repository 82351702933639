import useChangeTitle from '../../api/hooks/useChangeTitle';
import { getErrorTranslation } from '../../api/utils/errorKeys';
import useTranslate from '../../translation/i18n';

type Props = {
    userCode: string;
    codeExists: () => void;
    verificationError: string;
    handleUserCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleVerifyDeviceCode: (e: React.MouseEvent<HTMLElement>) => void;
};

export const DeviceActivation = ({
    userCode,
    codeExists,
    verificationError,
    handleUserCodeChange,
    handleVerifyDeviceCode,
}: Props) => {
    const { t } = useTranslate();

    const title = t('activation.title.initial');
    useChangeTitle(title);

    return (
        <div id="activate-your-device" className="activate-your-device">
            <div
                id="activate-your-device-overlay"
                className="activate-your-device-overlay"
            >
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <p>{t('activation.description.initial')}</p>
                </div>
                <div className="pure-control-group">
                    <div className="input-wrapper">
                        <input
                            id="device-code"
                            type="text"
                            className={
                                verificationError ? 'error_input' : undefined
                            }
                            defaultValue={userCode}
                            onChange={handleUserCodeChange}
                            onBlur={codeExists}
                            data-testid="code-input"
                            required
                        />
                        <label
                            htmlFor="device-code"
                            className={
                                verificationError ? 'label-error' : undefined
                            }
                        >
                            {t('activation.input.placeholder')}
                        </label>
                    </div>
                </div>
                {verificationError && (
                    <div
                        id="activate-your-device-error-layout"
                        className={'activate-your-device-error-layout'}
                    >
                        <span className="error">
                            {t(getErrorTranslation(verificationError))}
                        </span>
                    </div>
                )}
                <div className="sticky_bottom">
                    <div className="pure-controls">
                        <p>
                            <button
                                id="verify-device-code"
                                data-testid="activate-button"
                                onClick={handleVerifyDeviceCode}
                            >
                                {t('activation.button')}
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
