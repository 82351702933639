import {
    TelemetryBaseEvent,
    TelemetryBaseEventData,
} from '../models/telemetry';
import { LOCATION } from './telemetryConstants';
import { config, getApiStageFromQuery } from '../../config/config';
import platform from 'platform';
import { VERSION } from '../../config/buildInfo';

export function buildBaseEventData(): TelemetryBaseEventData {
    return {
        osVersion: platform.os ? platform.os.toString() : 'unknown',
        browserType: platform.name ? platform.name : 'unknown',
    };
}

export function buildBaseEvent(): TelemetryBaseEvent {
    return {
        // Random 9 digit number
        sequenceNumber: Math.floor(100000 + Math.random() * 900000),
        // Unix time
        timestamp: Math.floor(new Date().getTime() / 1000),
        metricsVersion: config.telemetry.metricVersion,
        appVersion: VERSION,
        backendStage: getApiStageFromQuery().prettyName,
        location: LOCATION,
    };
}
