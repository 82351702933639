import { RefObject } from 'react';
import useTranslate from '../../translation/i18n';
import useChangeTitle from '../../api/hooks/useChangeTitle';

type Props = {
    emailRef: RefObject<HTMLInputElement>;
    error: boolean;
    handleEmailBlur: () => void;
    handleKeyDown: (e: React.KeyboardEvent) => void;
    handleSubmit: () => void;
};
export default function ForgotPassword({
    emailRef,
    error,
    handleEmailBlur,
    handleKeyDown,
    handleSubmit,
}: Props) {
    const { t } = useTranslate();
    const title = t('reset_password.title.initial');
    useChangeTitle(title);

    return (
        <div id="forgot-password" className="forgot-password">
            <div
                id="forgot-password-overlay"
                className="forgot-password-overlay"
            >
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <p>{t('reset_password.description.initial')}</p>
                </div>
                <div id="email" className="pure-control-group">
                    <div className="input-wrapper">
                        <input
                            id="email"
                            ref={emailRef}
                            name="email"
                            type="text"
                            title={t('login.email.input.error')}
                            autoComplete="email"
                            onBlur={handleEmailBlur}
                            onKeyDown={handleKeyDown}
                            className={error ? 'error_input' : undefined}
                            data-testid="email-input"
                            required
                        />
                        <label
                            htmlFor="email"
                            className={error ? 'label-error' : undefined}
                        >
                            {t('reset_password.email.input.placeholder')}
                        </label>
                    </div>
                </div>
                <div className="email-invalid-layout">
                    {error && (
                        <span
                            className="error"
                            id="invalid-email"
                            data-testid="input-error"
                        >
                            {t('reset_password.email.input.error')}
                        </span>
                    )}
                </div>
                <div className="pure-controls">
                    <button
                        id="reset-password"
                        data-testid="reset-password-button"
                        onClick={handleSubmit}
                    >
                        {t('reset_password.button.initial')}
                    </button>
                </div>
            </div>
        </div>
    );
}
