import { useState } from 'react';
import { useVerifyUserCode } from '../../api/hooks/useVerifyUserCode';
import { DeviceActivation } from './DeviceActivation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Spinner } from '../Spinner/Spinner';
import { API_ERROR_KEYS } from '../../api/utils/errorKeys';

export const DeviceActivationContainer = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const user_code = searchParams.get('user_code') || '';
    const [userCode, setUserCode] = useState(user_code);

    const { verifyUserCode, isLoading } = useVerifyUserCode();
    const { setDeviceCode } = useAuth();

    const [verificationError, setVerificationError] = useState('');

    const handleUserCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserCode(e.target.value);
    };

    function codeExists() {
        const exists = userCode ? true : false;
        setVerificationError(
            exists
                ? ''
                : API_ERROR_KEYS.verify_device_code
                      .input_validation_error_message,
        );
        return exists;
    }

    const handleVerifyDeviceCode = () => {
        if (codeExists()) {
            verifyUserCode(userCode, {
                onSuccess: () => {
                    setDeviceCode(userCode);
                    navigate('/renderer/device/login');
                },
                onError: (error) => {
                    if (error.response && error.response.data.errorKeys)
                        setVerificationError(error.response.data.errorKeys);
                },
            });
        }
    };

    if (isLoading) return <Spinner />;

    return (
        <DeviceActivation
            {...{
                userCode,
                codeExists,
                verificationError,
                handleUserCodeChange,
                handleVerifyDeviceCode,
            }}
        />
    );
};
