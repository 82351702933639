import { useEffect, useState } from 'react';
import { useCoefficients } from '../../api/hooks/useCoefficients';
import { getOktaConfig, getRoomModel } from '../../config/config';
import { useAuth } from '../../contexts/AuthContext';
import { Confirmation } from './Confirmation';
import { Spinner } from '../Spinner/Spinner';
import { Details } from './Details';
import { telemetryService } from '../../api/telemetry/telemetryService';
import { useNavigate } from 'react-router-dom';
import OktaAuth from '@okta/okta-auth-js';

export const DetailsContainer = ({ mode }: { mode: string }) => {
    const navigate = useNavigate();
    const roomModel = getRoomModel(mode);

    const { currentUser, userId, customerType, oktaUserId } = useAuth();

    if (!currentUser || !userId || !roomModel || !customerType || !oktaUserId)
        throw new Error(
            'One of the following is missing: currentUser, userId, roomModel, customerType, oktaUserId.',
        );
    const { data, isLoading } = useCoefficients(
        currentUser.login,
        userId,
        roomModel,
        currentUser.accessToken,
        customerType,
        oktaUserId,
    );
    const [isConfirmation, setIsConfirmation] = useState<boolean>(false);

    const handleDownload = () => {
        telemetryService.sendButtonClickEvent(
            userId,
            customerType,
            oktaUserId,
            data!.metadata.uuid,
        );
        setIsConfirmation(true);
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            const oktaClient = new OktaAuth(getOktaConfig());
            sessionStorage.clear();
            await oktaClient.signOut({
                postLogoutRedirectUri: `${window.location.origin}/renderer`,
                state: 'expired_session',
            });
        }, 3600000); // 60 minutes
        return () => clearTimeout(timer);
    }, [navigate]);

    if (isLoading) return <Spinner />;

    return isConfirmation ? (
        <Confirmation
            downloadUrl={data!.downloadUrl}
            fileName={data!.metadata.name}
            email={currentUser.login}
        />
    ) : (
        <Details
            data={data!}
            email={currentUser.login}
            name={`${currentUser.firstName} ${currentUser.lastName} `}
            accessToken={currentUser.accessToken}
            handleDownload={handleDownload}
        />
    );
};
