import axios from 'axios';
import { getApiStageFromQuery } from '../../config/config';

export const api = axios.create({
    baseURL: getApiStageFromQuery().domain,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const telemetryApi = axios.create({
    baseURL: getApiStageFromQuery().telemetryDomain,
    headers: {
        'Content-Type': 'application/json',
    },
});
