import { createContext, useContext, useState } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { config, getOktaConfig } from '../config/config';
import { User } from '../api/models/user';
import log from 'loglevel';
import { telemetryService } from '../api/telemetry/telemetryService';
import { getUserData } from '../api/fetches/getUserData';
import {
    CustomerTypes,
    OktaServerResponses,
    OktaUserStatuses,
    UserFlows,
} from '../api/telemetry/telemetryConstants';
import { getCustomerType } from '../api/telemetry/getCustomerType';

export interface IAuthContext {
    currentUser: User | undefined;
    sessionToken: string | undefined;
    userId: string | undefined;
    customerType: CustomerTypes | undefined;
    oktaUserId: string | undefined;
    deviceCode: string | undefined;
    setDeviceCode: React.Dispatch<React.SetStateAction<string | undefined>>;
    login: (
        email: string,
        password: string,
        device: boolean | undefined,
    ) => Promise<void>;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function useAuth() {
    return useContext(AuthContext);
}

type Props = {
    children?: React.ReactNode;
};

export function AuthProvider({ children }: Props): JSX.Element {
    const [currentUser, setCurrentUser] = useState<User>();
    const [userId, setUserId] = useState<string>();
    const [sessionToken, setSessionToken] = useState<string>();
    const [deviceCode, setDeviceCode] = useState<string>();
    const [customerType, setCustomerType] = useState<CustomerTypes>();
    const [oktaUserId, setOktaUserId] = useState<string>();

    async function login(
        username: string,
        password: string,
        device: boolean | undefined,
    ) {
        const oktaClient = new OktaAuth(getOktaConfig());

        if (sessionStorage.length) await oktaClient.closeSession();

        const oktaSignInResponse = await oktaClient.signInWithCredentials({
            username,
            password,
        });
        log.info('Okta signIn response:');
        log.info(oktaSignInResponse);

        const { sessionToken, user } = oktaSignInResponse;
        setSessionToken(sessionToken);

        if (device) return;

        const authTokenResponse = await oktaClient.token.getWithoutPrompt({
            sessionToken,
            scopes: config.okta.default.scopes,
            timeout: config.okta.default.timeout, // 5s timeout on error
        });
        log.info('authTokenResponse : ', authTokenResponse);

        oktaClient.tokenManager.setTokens(authTokenResponse.tokens);

        const tokens = await oktaClient.tokenManager.getTokens();
        log.info('Tokens: ', tokens);

        const oktaUserId = tokens.idToken?.claims.sub;

        const { userId, status, userProfile } = await getUserData(
            tokens.accessToken!.accessToken,
            username,
        );

        const newCustomerType = getCustomerType(userProfile);

        setCurrentUser({
            ...user?.profile,
            accessToken: tokens.accessToken!.accessToken,
        });
        setUserId(userId);
        setCustomerType(newCustomerType);
        setOktaUserId(oktaUserId);

        telemetryService.sendOktaUserStatusEvent(
            OktaServerResponses.SUCCESS,
            OktaUserStatuses.ACTIVE,
            UserFlows.WEBAPP,
            oktaUserId,
            userId,
            newCustomerType,
        );

        telemetryService.sendSignInEvent(
            status,
            userId,
            newCustomerType,
            oktaUserId,
        );
    }

    const context: IAuthContext = {
        currentUser,
        userId,
        customerType,
        oktaUserId,
        sessionToken,
        deviceCode,
        setDeviceCode,
        login,
    };

    return (
        <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    );
}
