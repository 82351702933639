import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary';
import { useEffect, useState } from 'react';

import { API_ERROR_KEYS, getErrorTranslation } from '../api/utils/errorKeys';
import { AxiosError } from 'axios';

import Issue from './Issue/Issue';
import useTranslate from '../translation/i18n';

type Props = {
    children?: React.ReactNode;
};

export const IssueHandler = withErrorBoundary(({ children }: Props) => {
    const [error]: [any, any] = useErrorBoundary();
    const { t } = useTranslate();

    if (error) {
        let errorMessage;
        let contact = false;
        if (
            error instanceof AxiosError &&
            error.response &&
            error.response.data
        ) {
            const { errorKeys, message } = error.response.data;

            if (errorKeys === API_ERROR_KEYS.issue.capture_not_exists) {
                const email = message.split('user')[1];
                errorMessage = t(getErrorTranslation(errorKeys)) + email;
            } else if (errorKeys === API_ERROR_KEYS.internal_error) {
                errorMessage = 'Server error, ';
                contact = true;
            } else {
                errorMessage = t(getErrorTranslation(errorKeys, message));
            }
        } else {
            errorMessage = error.message;
        }
        return <Issue message={errorMessage} contact={contact} />;
    }

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };
        window.addEventListener('online', handleStatusChange);
        window.addEventListener('offline', handleStatusChange);

        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    if (!isOnline) {
        return <Issue message={t('phrtf.downloading.description.error')} />;
    }

    return children as any;
});
