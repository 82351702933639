import { Spinner } from '../Spinner/Spinner';
import { RefObject, useRef, useState, KeyboardEvent } from 'react';
import Login from './Login';
import DeviceLogin from './DeviceLogin';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { isEmailValid, isPasswordValid } from '../utils/validators';
import { telemetryService } from '../../api/telemetry/telemetryService';
import {
    OktaServerResponses,
    OktaUserStatuses,
    UserFlows,
} from '../../api/telemetry/telemetryConstants';
import { useSendVerificationEmail } from '../../api/hooks/useSendVerificationEmail';
import { useIsUserActivationPending } from '../../api/hooks/useIsUserActivationPending';

export type LoginProps = {
    emailError: boolean;
    passwordError: boolean;
    emailRef: RefObject<HTMLInputElement>;
    passwordRef: RefObject<HTMLInputElement>;
    handleEmailOnFocus: () => void;
    handlePasswordOnFocus: () => void;
    handleOnEnterKey: (e: KeyboardEvent<HTMLInputElement>) => Promise<void>;
    handleSignInButtonOnClick: () => Promise<void>;
    loginError: boolean;
    sessionExpired: boolean;
    device?: boolean;
};

export default function LoginContainer({ device }: { device?: boolean }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { login } = useAuth();
    const { sendVerificationEmail } = useSendVerificationEmail();
    const { checkIfUserActivationPending } = useIsUserActivationPending();

    const [loginError, setLoginError] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    // on change focus handlers
    const handleEmailOnFocus = () =>
        setEmailError(!isEmailValid(emailRef.current!.value));
    const handlePasswordOnFocus = () =>
        setPasswordError(!isPasswordValid(passwordRef.current!.value));

    // handleOnKeyDown
    const handleOnEnterKey = async (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            await handleSubmit(
                emailRef.current!.value,
                passwordRef.current!.value,
            );
        }
    };

    // on sign in button click
    const handleSignInButtonOnClick = () =>
        handleSubmit(emailRef.current!.value, passwordRef.current!.value);

    async function handleSubmit(email: string, password: string) {
        const emailInvalidCondition = !email || !isEmailValid(email);
        const passwordInvalidCondition =
            !password || !isPasswordValid(password);

        if (emailInvalidCondition) {
            setEmailError(true);
        }

        if (passwordInvalidCondition) {
            setPasswordError(true);
        }

        if (emailInvalidCondition || passwordInvalidCondition) {
            // interrupt execution when one or several conditions were met
            return;
        }

        setIsLoading(true);
        try {
            await login(email, password, device);

            if (device) navigate('/renderer/device/status');
            else {
                const debug = searchParams.get('debug');
                navigate({
                    pathname: '/renderer/details',
                    search: debug ? `?debug=${debug}` : '',
                });
            }
        } catch (e) {
            checkIfUserActivationPending(email, {
                onSuccess({ isUserActivationPending, oktaUserId }) {
                    if (isUserActivationPending) {
                        telemetryService.sendOktaUserStatusEvent(
                            OktaServerResponses.SUCCESS,
                            OktaUserStatuses.INACTIVE,
                            device ? UserFlows.XBOX : UserFlows.WEBAPP,
                            oktaUserId,
                        );
                        sendVerificationEmail(oktaUserId, {
                            onSuccess: () => {
                                navigate(
                                    '/renderer/verification-email-resent',
                                    {
                                        state: device,
                                    },
                                );
                            },
                            onError: () => {
                                navigate('/renderer/activation-error');
                            },
                        });
                    } else {
                        telemetryService.sendOktaUserStatusEvent(
                            OktaServerResponses.SUCCESS,
                            OktaUserStatuses.UNKNOWN,
                            device ? UserFlows.XBOX : UserFlows.WEBAPP,
                        );
                        setLoginError(true);
                        setIsLoading(false);
                    }
                },
                onError() {
                    telemetryService.sendOktaUserStatusEvent(
                        OktaServerResponses.UNKNOWN,
                        OktaUserStatuses.NONE,
                        device ? UserFlows.XBOX : UserFlows.WEBAPP,
                    );
                    setLoginError(true);
                    setIsLoading(false);
                },
            });
        }
    }

    if (isLoading) return <Spinner />;

    const loginProps: LoginProps = {
        emailError,
        passwordError,
        emailRef,
        passwordRef,
        handleEmailOnFocus,
        handlePasswordOnFocus,
        handleOnEnterKey,
        handleSignInButtonOnClick,
        loginError,
        sessionExpired: !!searchParams.get('state'),
        device,
    };

    if (device) return <DeviceLogin {...loginProps} />;

    return <Login {...loginProps} />;
}
