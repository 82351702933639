import { useQuery } from '@tanstack/react-query';
import { api } from '../utils/axios';
import { CoefficientsMapper } from '../mappers/coefficients';
import { CoefficientsModel } from '../models/coefficients';
import { AxiosError } from 'axios';
import { telemetryService } from '../telemetry/telemetryService';
import log from 'loglevel';
import { CoefficientsDTO } from '../dto/coefficients';
import { CustomerTypes } from '../telemetry/telemetryConstants';

export const useCoefficients = (
    email: string,
    userId: string,
    roomModel: string,
    accessToken: string,
    customerType: CustomerTypes,
    oktaUserId: string,
) => {
    const { data, isLoading } = useQuery<CoefficientsModel, Error>(
        ['getCoefficients'],
        async () => {
            const response = await api.get<CoefficientsDTO>(
                `/getcoefficients`,
                {
                    params: {
                        email,
                        room_model: roomModel,
                    },
                    headers: {
                        Authorization: `bearer ${accessToken}`,
                    },
                },
            );

            log.info('Get coefficients response:');
            log.info(response);
            telemetryService.sendHrtfDownloadEvent(
                response.status,
                response.data,
                userId,
                customerType,
                oktaUserId,
            );
            return CoefficientsMapper.toDomain(response.data);
        },
        {
            useErrorBoundary: true,
            onError: (e) => {
                if (e instanceof AxiosError && e.response) {
                    telemetryService.sendHrtfDownloadEvent(
                        e.response.status,
                        e.response.data,
                        userId,
                        customerType,
                        oktaUserId,
                    );
                }
            },
        },
    );
    return { data, isLoading };
};
