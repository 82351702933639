import { AxiosError } from 'axios';
import { api } from '../utils/axios';
import { useMutation } from '@tanstack/react-query';
import log from 'loglevel';

export const useChangePassword = () => {
    const { mutate: changePassword, isLoading } = useMutation<
        void,
        AxiosError,
        { password: string; recoveryToken: string }
    >(async ({ password, recoveryToken }) => {
        const response = await api.post(`/users/change_password`, {
            newPassword: password,
            recoveryToken,
        });

        log.info('Change password response:');
        log.info(response);
    });
    return { changePassword, isLoading };
};
