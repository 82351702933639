import useChangeTitle from '../../api/hooks/useChangeTitle';
import useTranslate from '../../translation/i18n';

export function ChangePasswordLinkExpired() {
    const { t } = useTranslate();

    const title = t('change_password.error.token_expired.title');
    useChangeTitle(title);

    return (
        <div
            id="recovery-token-invalid"
            className="recovery-token-invalid not-displayed"
        >
            <div
                id="recovery-token-invalid-overlay"
                className="recovery-token-invalid-overlay"
            >
                <div className="row icon">
                    <img
                        data-testid="issue-image"
                        alt="issue"
                        src="/images/status/issue.png"
                    />
                </div>

                <div className="row title">
                    <h1>{title}</h1>
                </div>

                <div className="row description">
                    <span>
                        {t('change_password.error.token_expired.message')}
                    </span>
                </div>
            </div>
        </div>
    );
}
