import { useEffect, useState } from 'react';
import { getOktaConfig } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useChangePassword } from '../../api/hooks/useChangePassword';
import { ChangePassword } from './ChangePassword';
import { Spinner } from '../Spinner/Spinner';
import { isPasswordFormatValid, isPasswordValid } from '../utils/validators';
import OktaAuth from '@okta/okta-auth-js';

export function ChangePasswordContainer({
    recoveryToken,
}: {
    recoveryToken: string;
}) {
    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                const oktaClient = new OktaAuth(getOktaConfig());

                await oktaClient.verifyRecoveryToken({
                    recoveryToken,
                });
            } catch (e) {
                navigate('/renderer/forgot-password/token-invalid');
            } finally {
                setIsVerifyLoading(false);
            }
        })();
    }, [recoveryToken, navigate]);

    const { changePassword, isLoading } = useChangePassword();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showPassRequestError, setPassRequestError] = useState(false);
    const [isVerifyLoading, setIsVerifyLoading] = useState(true);

    const isValid =
        isPasswordFormatValid(newPassword) && isPasswordValid(newPassword);
    const isMatching = newPassword === confirmPassword;

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') handleSubmit();
    }

    function handleSubmit() {
        if (isValid && isMatching) {
            changePassword(
                {
                    password: newPassword,
                    recoveryToken,
                },
                {
                    onSuccess() {
                        navigate('/renderer/forgot-password/confirmation');
                    },
                    onError(e) {
                        if (e.response && e.response.status === 401)
                            navigate('/renderer/forgot-password/token-invalid');
                        else setPassRequestError(true);
                    },
                },
            );
        }
    }

    if (isVerifyLoading || isLoading) return <Spinner />;

    return (
        <ChangePassword
            {...{
                showNewPassError:
                    (!!newPassword && !isValid) || showPassRequestError,
                setNewPassword,
                showConfirmPassError: !!confirmPassword && !isMatching,
                setConfirmPassword,
                setPassRequestError,
                handleKeyDown,
                handleSubmit,
                disableButton: !newPassword || !isValid || !isMatching,
            }}
        />
    );
}
