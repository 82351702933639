import { useNavigate } from 'react-router';
import useTranslate from '../../translation/i18n';
import useChangeTitle from '../../api/hooks/useChangeTitle';
import { useLocation } from 'react-router-dom';

export default function VerificationEmailResent() {
    const navigate = useNavigate();
    const { t } = useTranslate();
    const { state } = useLocation();

    const title = t('verification.title.resent');
    useChangeTitle(title);

    function backToSignIn() {
        navigate('/renderer');
    }

    return (
        <div
            id="verification-email-sent"
            className="verification-email-sent not-displayed"
        >
            <div
                id="verification-email-sent-overlay"
                className="verification-email-sent-overlay"
            >
                <div className="row">
                    <h1>{title}</h1>
                </div>
                <div className="row">
                    <p>{t('verification.description.main.resent')}</p>
                </div>
                {state ? (
                    <div>{t('verification.instructions')}</div>
                ) : (
                    <div className="sticky_bottom">
                        <div className="pure-controls">
                            <a
                                id="back-to-sign-in"
                                data-testid="back-to-sign-in-button"
                                onClick={backToSignIn}
                            >
                                {t('common.back_sign_in')}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
