import { AxiosError } from 'axios';
import { api } from '../utils/axios';
import log from 'loglevel';

export async function getUserData(accessToken: string, email: string) {
    try {
        const response = await api.get('/getuserdata', {
            params: {
                email,
            },
            headers: {
                Authorization: `bearer ${accessToken}`,
            },
        });

        log.info('Get user data response: ');
        log.info(response);

        log.info(`[CONFIG] Backend version: ${response.data.backend_version}`);

        return {
            userId: response.data.user_id,
            status: response.data.status,
            userProfile: response.data.userProfile,
        };
    } catch (e) {
        if (e instanceof AxiosError && e.response) {
            throw new Error(e.response.data.message);
        }
        throw e;
    }
}
