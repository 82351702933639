import OktaAuth from '@okta/okta-auth-js';
import useTranslate from '../../translation/i18n';
import { getOktaConfig } from '../../config/config';

export function NavigationBar() {
    const { t } = useTranslate();

    async function handleSignOut() {
        const oktaClient = new OktaAuth(getOktaConfig());
        sessionStorage.clear();
        await oktaClient.signOut({
            postLogoutRedirectUri: `${window.location.origin}/renderer`,
        });
    }

    return (
        <div className="header">
            <div className="row reverse">
                <button
                    id="btn-sign-out"
                    className="ghost-button"
                    onClick={handleSignOut}
                >
                    {t('phrtf.navbar.sign_out')}
                </button>
                <a
                    href="https://professional.dolby.com/phrtf/support/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <button id="btn-contact-us" className="ghost-button">
                        {t('phrtf.navbar.contact')}
                    </button>
                </a>
            </div>
        </div>
    );
}
