import React from 'react';
import './App.scss';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { config } from './config/config';
import { DetailsContainer } from './components/Details/DetailsContainer';
import { UserActivation } from './components/UserActivation/UserActivation';
import { DispatchRouteOnQuery } from './components/DispatchRouteOnQuery';
import { DeviceActivationStatus } from './components/DeviceActivation/DeviceActivationStatus';
import { useAuth } from './contexts/AuthContext';
import { DeviceActivationContainer } from './components/DeviceActivation/DeviceActivationContainer';
import { ChangePasswordConfirmation } from './components/ChangePassword/ChangePasswordConfirmation';
import { ChangePasswordLinkExpired } from './components/ChangePassword/ChangePasswordLinkExpired';
import ForgotPasswordConfirmation from './components/ForgotPassword/ForgotPasswordConfirmation';
import ForgotPasswordContainer from './components/ForgotPassword/ForgotPasswordContainer';
import VerificationEmailResent from './components/UserActivation/VerificationEmailResent';
import LoginContainer from './components/Login/LoginContainer';
import { UserActivationStatus } from './components/UserActivation/UserActivationStatus';

function App() {
    const { currentUser } = useAuth();

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="activate"
                    element={<DeviceActivationContainer />}
                />
                <Route
                    path="activate-email"
                    element={<UserActivationStatus />}
                />
                <Route
                    path="forgot-password"
                    element={<ForgotPasswordContainer />}
                />
                <Route path="renderer" element={<DispatchRouteOnQuery />}>
                    <Route index element={<LoginContainer />} />

                    {currentUser && (
                        <Route
                            path="details"
                            element={
                                <DetailsContainer
                                    mode={config.modes.renderer}
                                />
                            }
                        />
                    )}

                    <Route
                        path="device/status"
                        element={<DeviceActivationStatus />}
                    />

                    <Route
                        path="activation-error"
                        element={<UserActivation />}
                    />

                    <Route
                        path="device/login"
                        element={<LoginContainer device />}
                    />

                    <Route
                        path="verification-email-resent"
                        element={<VerificationEmailResent />}
                    />

                    <Route path="forgot-password">
                        <Route index element={<ForgotPasswordContainer />} />
                        <Route
                            path="email-sent"
                            element={<ForgotPasswordConfirmation />}
                        />
                        <Route
                            path="confirmation"
                            element={<ChangePasswordConfirmation />}
                        />
                        <Route
                            path="token-invalid"
                            element={<ChangePasswordLinkExpired />}
                        />
                    </Route>
                </Route>

                <Route
                    path="*"
                    element={<Navigate to="renderer" replace />}
                ></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
