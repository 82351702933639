const PROD_DEPLOYMENT_STAGE_ID = 'PROD';
const PROD_STAGE_ID = 'prod-02';
const QA_STAGE_ID = 'qa';
const SIT_STAGE_ID = 'sit-02';
const TEST_STAGE_ID = 'test';
const DEFAULT_STAGE_ID = SIT_STAGE_ID;

type ApiStages =
    | typeof PROD_STAGE_ID
    | typeof QA_STAGE_ID
    | typeof SIT_STAGE_ID
    | typeof TEST_STAGE_ID;

const OKTA_PREVIEW_TENANT_CONFIG = {
    issuer: process.env.REACT_APP_OKTA_PREVIEW_ISSUER,
    clientId: process.env.REACT_APP_OKTA_PREVIEW_CLIENT_ID,
};

const OKTA_RELEASE_TENANT_CONFIG = {
    issuer: process.env.REACT_APP_OKTA_RELEASE_ISSUER,
    clientId: process.env.REACT_APP_OKTA_RELEASE_CLIENT_ID,
};

const OKTA_TEST_TENANT_CONFIG = {
    issuer: 'testissuer',
    clientId: 'testclientid',
};

const TELEMETRY_DOMAIN = process.env.REACT_APP_TELEMETRY_DOMAIN;

export const config = {
    okta: {
        preview: OKTA_PREVIEW_TENANT_CONFIG,
        release: OKTA_RELEASE_TENANT_CONFIG,
        default: {
            scopes: ['openid', 'email', 'profile'],
            storage: 'sessionStorage',
            useInteractionCodeFlow: true,
            requireUserSession: 'true',
            authMethod: 'form',
            startService: true,
            useDynamicForm: false,
            uniq: Date.now() + Math.round(Math.random() * 1000), // to guarantee a unique state
            idps: '',
            redirectUri: '/renderer/',
            // scopes: ['openid', 'email', 'profile', 'offline_access'],
            timeout: 5000, // 5s timeout on error
        },
    },
    api: {
        [QA_STAGE_ID]: {
            prettyName: 'qa',
            domain: process.env.REACT_APP_BACKEND_QA_DOMAIN,
            telemetryDomain: TELEMETRY_DOMAIN,
            oktaTenantConfig: OKTA_PREVIEW_TENANT_CONFIG,
            oktaTenantName: 'PREVIEW',
        },
        [SIT_STAGE_ID]: {
            prettyName: 'sit-02',
            domain: process.env.REACT_APP_BACKEND_SIT_DOMAIN,
            telemetryDomain: TELEMETRY_DOMAIN,
            oktaTenantConfig: OKTA_RELEASE_TENANT_CONFIG,
            oktaTenantName: 'RELEASE',
        },
        [PROD_STAGE_ID]: {
            prettyName: 'prod-02',
            domain: process.env.REACT_APP_BACKEND_PROD_DOMAIN,
            telemetryDomain: TELEMETRY_DOMAIN,
            oktaTenantConfig: OKTA_RELEASE_TENANT_CONFIG,
            oktaTenantName: 'RELEASE',
        },
        [TEST_STAGE_ID]: {
            prettyName: 'test',
            domain: 'test://test.test/test',
            telemetryDomain: 'test://telemetry.test',
            oktaTenantConfig: OKTA_TEST_TENANT_CONFIG,
            oktaTenantName: 'TEST',
        },
    },
    modes: {
        renderer: 'renderer',
        gaming: 'gaming',
    },
    models: {
        studio_purple: 'Studio2F',
        gaming_classic: 'GamingClassic',
    },
    telemetry: {
        endpoint: '/ingest',
        metricVersion: 1,
    },
};

export const getRoomModel = (mode: string) => {
    return mode === config.modes.renderer
        ? config.models.studio_purple
        : config.models.gaming_classic;
};

export const getApiStageFromQuery = () => {
    if (isTest()) {
        return _getApiStageConfig(TEST_STAGE_ID);
    }
    if (isProd()) {
        return _getApiStageConfig(PROD_STAGE_ID);
    }
    const stageFromParams = _getQueryParam('stage');

    if (stageFromParams)
        sessionStorage.setItem('stage', stageFromParams.toLowerCase());

    const stage = _getApiStageConfig(
        (sessionStorage.getItem('stage') || DEFAULT_STAGE_ID) as ApiStages,
    );

    return stage;
};

export const getOktaConfig = () => {
    const { oktaTenantConfig } = getApiStageFromQuery();
    return {
        issuer: oktaTenantConfig.issuer,
        clientId: oktaTenantConfig.clientId,
        redirectUri: config.okta.default.redirectUri,
        scopes: config.okta.default.scopes,
        // useInteractionCodeFlow: oktaConfig.useInteractionCodeFlow,
        tokenManager: {
            storage: isTest() ? 'memory' : config.okta.default.storage,
        },
    };
};

export function isProd() {
    return process.env.REACT_APP_DEPLOYMENT_ENV === PROD_DEPLOYMENT_STAGE_ID;
}

export function isTest() {
    return process.env.NODE_ENV === TEST_STAGE_ID;
}

const _getApiStageConfig = (stage: ApiStages) => {
    return config.api[stage] || config.api[DEFAULT_STAGE_ID];
};

const _getQueryParam = (paramName: string) => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get(paramName);
};
