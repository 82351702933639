import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isTest } from './config/config';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
        mutations: {
            retry: false,
            useErrorBoundary: (error) => errorKeyCatcher(error),
            onError: (error) => _throwError(error),
        },
    },
    logger: isTest()
        ? {
              log: console.log,
              warn: console.warn,
              error: () => {},
          }
        : undefined,
});

export function errorKeyCatcher(error: unknown) {
    if (error instanceof AxiosError) {
        if (
            (error.response && error.response.data.errorKeys) ||
            error.status === 404
        )
            return false;
    }

    return true;
}

function _throwError(e: unknown) {
    if (e instanceof AxiosError) {
        if (e.response) {
            throw new Error(e.response?.data.message);
        }
        throw new Error(e.message);
    }
    throw e;
}
