import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api } from '../utils/axios';
import log from 'loglevel';

export const useIsUserActivationPending = () => {
    const { mutateAsync: checkIfUserActivationPending } = useMutation<
        {
            isUserActivationPending: boolean;
            oktaUserId: string;
        },
        AxiosError<{ message: string; errorKeys: string }>,
        string
    >(['isUserActivationPending'], async (email) => {
        const response = await api.get<{
            is_user_activation_pending: boolean;
            user_id: string;
        }>('/isuseractivationpending', {
            params: {
                email,
            },
        });

        log.info('Get user activation pending response:');
        log.info(response);
        return {
            isUserActivationPending: response.data.is_user_activation_pending,
            oktaUserId: response.data.user_id,
        };
    });
    return { checkIfUserActivationPending };
};
