import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResetPassword } from '../../api/hooks/useResetPassword';
import { Spinner } from '../Spinner/Spinner';
import ForgotPassword from './ForgotPassword';
import { isEmailValid } from '../utils/validators';
import { useIsUserActivationPending } from '../../api/hooks/useIsUserActivationPending';
import { useSendVerificationEmail } from '../../api/hooks/useSendVerificationEmail';

export default function ForgotPasswordContainer() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const { resetPassword } = useResetPassword();
    const { checkIfUserActivationPending } = useIsUserActivationPending();
    const { sendVerificationEmail } = useSendVerificationEmail();

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const emailRef = useRef<HTMLInputElement>(null);

    function handleEmailBlur() {
        const check = !isEmailValid(emailRef.current!.value);
        if (check !== error) setError(check);
    }

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') handleSubmit();
    }

    async function handleSubmit() {
        const email = emailRef.current?.value;
        if (email && isEmailValid(email)) {
            setIsLoading(true);

            const { isUserActivationPending, oktaUserId } =
                await checkIfUserActivationPending(email);

            if (isUserActivationPending) {
                sendVerificationEmail(oktaUserId, {
                    onSuccess: () => {
                        navigate('/renderer/verification-email-resent', {
                            state: !(state && state.fromLogin),
                        });
                    },
                    onError: () => {
                        navigate('/renderer/activation-error');
                    },
                });
            } else {
                resetPassword(email, {
                    onSuccess() {
                        navigate('/renderer/forgot-password/email-sent', {
                            state: {
                                email,
                                fromLogin: state && state.fromLogin,
                            },
                        });
                    },
                    onError() {
                        setError(true);
                        setIsLoading(false);
                    },
                });
            }
        } else setError(true);
    }

    if (isLoading) return <Spinner />;

    return (
        <ForgotPassword
            {...{
                emailRef,
                error,
                handleEmailBlur,
                handleKeyDown,
                handleSubmit,
            }}
        />
    );
}
