import useChangeTitle from '../../api/hooks/useChangeTitle';
import useTranslate from '../../translation/i18n';
import { NavigationBar } from '../NavigationBar/NavigationBar';

export default function Issue({
    message,
    contact,
}: {
    message: string;
    contact?: boolean;
}) {
    const { t } = useTranslate();

    const title = t('phrtf.downloading.title.error');
    useChangeTitle(title);

    return (
        <div className="details">
            <NavigationBar />
            <div id="issue" className="issue">
                <div className="content">
                    <div className="row">
                        <h1>{title}</h1>
                    </div>
                    <div className="row">
                        <svg
                            data-testid="issue-image"
                            version="1.0"
                            width="128.000000pt"
                            height="128.000000pt"
                            viewBox="0 0 128.000000 128.000000"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <g
                                transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                                fill="#FF0000"
                                stroke="none"
                            >
                                <path
                                    d="M595 1273 c-94 -11 -159 -29 -235 -65 -68 -33 -101 -57 -166 -122
-127 -126 -187 -270 -187 -446 0 -105 18 -181 65 -280 33 -68 57 -101 122
-166 126 -127 270 -187 446 -187 105 0 181 18 280 65 68 33 101 57 166 122
127 126 187 270 187 446 0 105 -18 181 -65 280 -57 120 -168 231 -288 288
-103 49 -236 76 -325 65z m164 -108 c102 -24 187 -72 261 -145 212 -212 212
-548 0 -760 -212 -212 -548 -212 -760 0 -212 212 -212 548 0 760 133 133 320
187 499 145z"
                                />
                                <path
                                    d="M606 1024 c-14 -13 -16 -50 -16 -245 0 -216 1 -230 20 -249 25 -25
35 -25 60 0 19 19 20 33 20 248 0 209 -2 230 -18 245 -23 21 -46 22 -66 1z"
                                />
                                <path
                                    d="M591 424 c-12 -15 -21 -32 -21 -39 0 -27 41 -65 70 -65 29 0 70 38
70 65 0 27 -41 65 -70 65 -19 0 -36 -9 -49 -26z"
                                />
                            </g>
                        </svg>
                    </div>

                    <div className="row">
                        <span id="error_message">
                            {message}
                            {contact && (
                                <a href="https://professional.dolby.com/phrtf/support/">
                                    contact us
                                </a>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
