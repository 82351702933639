import { AxiosError } from 'axios';
import { api } from '../utils/axios';
import { useMutation } from '@tanstack/react-query';
import log from 'loglevel';

export const useVerifyUserCode = () => {
    const { mutate: verifyUserCode, isLoading } = useMutation<
        void,
        AxiosError<{ message: string; errorKeys: string }>,
        string
    >(async (user_code) => {
        const response = await api.post('/auth/proxy/verify', {
            user_code,
        });

        log.info('User code verification response: ');
        log.info(response);
    });
    return { verifyUserCode, isLoading };
};
