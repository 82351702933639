import {
    CustomerTypes,
    TelemetryEventNames,
    TelemetryServerResponses,
    OktaServerResponses,
    OktaUserStatuses,
    UserFlows,
} from './telemetryConstants';

import { TelemetryEvent } from '../models/telemetry';
import { CaptureMetadataDTO, CoefficientsDTO } from '../dto/coefficients';
import {
    addUserInfo,
    getHRTFDownloadRoomModel,
    getHRTFDownloadServerResponseString,
    sendEvent,
} from './telemetryFunctions';
import { buildBaseEvent, buildBaseEventData } from './buildEvent';

export class telemetryService {
    static sendSignInEvent(
        status: number,
        userId?: string,
        customerType?: CustomerTypes,
        oktaUserId?: string,
    ) {
        let event: TelemetryEvent = {
            ...buildBaseEvent(),
            eventName: TelemetryEventNames.SIGN_IN,
            eventData: {
                ...buildBaseEventData(),
            },
        };
        if (oktaUserId) event.oktaUserId = oktaUserId;
        if (userId && customerType) addUserInfo(event, userId, customerType);
        if (status >= 400) {
            event.eventData.serverResponse =
                status === 404
                    ? TelemetryServerResponses.UNKNOWNUSER
                    : TelemetryServerResponses.CONNECTIONFAILURE;

            return sendEvent(event);
        }
        event.eventData.serverResponse = TelemetryServerResponses.SUCCESS;

        return sendEvent(event);
    }

    static sendHrtfDownloadEvent(
        status: number,
        coefficients: CoefficientsDTO,
        userId: string,
        customerType: CustomerTypes,
        oktaUserId: string,
    ) {
        const coefficientsMeta: CaptureMetadataDTO =
            coefficients.phrtf_capture_metadata;

        const serverResponse = getHRTFDownloadServerResponseString(
            status,
            coefficients.suez_status,
        );

        let event: TelemetryEvent = {
            ...buildBaseEvent(),
            eventName: TelemetryEventNames.HRTF_DOWNLOAD,
            industry: customerType,
            oktaUserId,
            eventData: {
                ...buildBaseEventData(),
                serverResponse,
            },
        };
        addUserInfo(event, userId, customerType);

        if (serverResponse === TelemetryServerResponses.SUCCESS) {
            event.eventData.roomModel = getHRTFDownloadRoomModel(
                coefficients.downloadUrl,
            );
            event.captureId = coefficientsMeta.uuid.toLowerCase();
        } else if (
            serverResponse === TelemetryServerResponses.NEEDSRECAPTURE ||
            serverResponse === TelemetryServerResponses.STILLPROCESSING
        ) {
            event.captureId = coefficients.uuid?.toLowerCase();
        }
        return sendEvent(event);
    }

    static sendButtonClickEvent(
        userId: string,
        customerType: CustomerTypes,
        oktaUserId: string,
        captureId?: string,
    ) {
        let event: TelemetryEvent = {
            ...buildBaseEvent(),
            eventName: TelemetryEventNames.BUTTON_CLICK,
            oktaUserId,
            eventData: {
                ...buildBaseEventData(),
                objectName: 'DownloadPhrtf',
            },
        };
        if (captureId) event.captureId = captureId;
        addUserInfo(event, userId, customerType);

        return sendEvent(event);
    }

    static sendOktaUserStatusEvent(
        oktaServerResponse: OktaServerResponses,
        userOktaStatus: OktaUserStatuses,
        userFlow: UserFlows,
        oktaUserId?: string,
        userId?: string,
        customerType?: CustomerTypes,
    ) {
        let event: TelemetryEvent = {
            ...buildBaseEvent(),
            eventName: TelemetryEventNames.USER_STATUS,
            oktaUserId: oktaUserId ? oktaUserId : null,
            eventData: {
                ...buildBaseEventData(),
                oktaServerResponse,
                userOktaStatus,
                userFlow,
            },
        };
        if (userId && customerType) addUserInfo(event, userId, customerType);

        return sendEvent(event);
    }
}
