import { Outlet, useSearchParams } from 'react-router-dom';
import { UserActivationStatus } from './UserActivation/UserActivationStatus';
import { DeviceActivationContainer } from './DeviceActivation/DeviceActivationContainer';
import { actions } from '../config/queryActions';
import { ChangePasswordContainer } from './ChangePassword/ChangePasswordContainer';
import ForgotPasswordContainer from './ForgotPassword/ForgotPasswordContainer';
import Issue from './Issue/Issue';
import useTranslate from '../translation/i18n';

export const DispatchRouteOnQuery = () => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslate();

    const action = searchParams.get('action');
    if (action) {
        if (action === actions.resetPassword) {
            // action=reset_password&code=_
            const recoveryToken = searchParams.get('code');
            if (recoveryToken) {
                return (
                    <ChangePasswordContainer recoveryToken={recoveryToken} />
                );
            } else {
                throw new Error(
                    t('change_password.error.token_expired.message'),
                );
            }
        } else if (action === actions.passwordReset) {
            // action=password_reset
            return <ForgotPasswordContainer />;
        } else if (action === actions.activate) {
            // action=activate&code=_&user_id=_
            return <UserActivationStatus />;
        } else if (action === actions.deviceAuth) {
            // action=device_auth(&user_code=_)->optional
            return <DeviceActivationContainer />;
        } else {
            return <Issue message="Action not found in link" />;
        }
    }

    return <Outlet />;
};
