import { Link, useLocation } from 'react-router-dom';
import useChangeTitle from '../../api/hooks/useChangeTitle';
import useTranslate from '../../translation/i18n';

export default function ForgotPasswordConfirmation() {
    const { t } = useTranslate();
    const { state } = useLocation();

    if (!state || !state.email) throw new Error('Email not in state');

    const email =
        state.email.length <= 40
            ? state.email
            : state.email.slice(0, 40) + '...';

    const title = t('reset_password.title.confirmation');
    useChangeTitle(title);

    return (
        <div id="email-sent" className="email-sent">
            <div id="email-sent-overlay" className="email-sent-overlay">
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <p>
                        {t('reset_password.description.with_email').replace(
                            '{0}',
                            email,
                        )}
                    </p>
                    {state.fromLogin ? (
                        <div id="back-to-sign-in">
                            <Link to="renderer">
                                {t('common.back_sign_in')}
                            </Link>
                        </div>
                    ) : (
                        <p>{t('activation.description.close_window')}</p>
                    )}
                </div>
            </div>
        </div>
    );
}
