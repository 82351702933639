import log from 'loglevel';
import { TelemetryEvent } from '../models/telemetry';
import { telemetryApi } from '../utils/axios';
import {
    CustomerTypes,
    TEST_USER_PREFIX,
    TelemetryServerResponses,
} from './telemetryConstants';
import { config } from '../../config/config';

export async function sendEvent(event: TelemetryEvent) {
    try {
        await telemetryApi.post(config.telemetry.endpoint, [event]);
        log.info(
            `TelemetryApi::sendEvent ${event.eventName} with sequenceNumber ${event.sequenceNumber} success:`,
        );
        log.info(event);
    } catch (e) {
        log.error(`TelemetryApi::sendEvent ${event.eventName} failed: ${e}`);
        log.info(event);
    }
}

export function isTestUser(userId: string): boolean {
    return userId.startsWith(TEST_USER_PREFIX);
}

export function addUserInfo(
    event: TelemetryEvent,
    userId: string,
    customerType: CustomerTypes,
) {
    event.userId = userId;
    event.isQA = isTestUser(userId);
    event.industry = customerType;
}

export function getHRTFDownloadServerResponseString(
    status?: number,
    suez_status?: string,
) {
    switch (status) {
        case 200:
            return TelemetryServerResponses.SUCCESS;
        case 400:
            return TelemetryServerResponses.UNKNOWNUSER;
        case 404:
            if (suez_status) {
                switch (suez_status) {
                    case 'SuccessLowConfidence':
                        return TelemetryServerResponses.NEEDSRECAPTURE;
                    case 'Processing':
                        return TelemetryServerResponses.STILLPROCESSING;
                    case 'Error':
                        return TelemetryServerResponses.SERVERERROR;
                }
            } else {
                return TelemetryServerResponses.NOTCAPTURED;
            }
            break;
        case 500:
            return TelemetryServerResponses.SERVERERROR;
    }
    return 'UnknownError';
}

export function getHRTFDownloadRoomModel(downloadUrl: string) {
    if (downloadUrl) {
        const regex = /amazonaws.com\/[\w-]+\/([^/]+)\//;
        const matches = downloadUrl.match(regex);
        if (matches && matches.length > 1) {
            return matches[1];
        } else {
            log.info('Room model not found in download url');
            return '';
        }
    } else {
        log.info('Download url is empty');
        return undefined;
    }
}
