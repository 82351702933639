import { AxiosError } from 'axios';
import { api } from '../utils/axios';
import { useQuery } from '@tanstack/react-query';
import log from 'loglevel';

const authorizeDeviceFetch = async (sessionToken: string, code: string) => {
    try {
        const { data } = await api.get('/auth/proxy/authorize', {
            params: {
                code,
                session_token: sessionToken,
            },
        });

        log.info('Authorize device response: ', data);
        return data;
    } catch (e) {
        if (e instanceof AxiosError) {
            throw new Error(e.response?.data.message);
        }
        throw e;
    }
};

export const useAuthorizeDevice = (sessionToken: string, code: string) => {
    const { isLoading, error } = useQuery<undefined, Error>(
        ['activateDevice', sessionToken, code],
        () => authorizeDeviceFetch(sessionToken, code),
    );
    return { isLoading, error };
};
