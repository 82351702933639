import { CoefficientsDTO } from '../dto/coefficients';
import { CoefficientsModel } from '../models/coefficients';

export class CoefficientsMapper {
    // public static toDTO(coefficients: CoefficientsResponse) {}

    public static toDomain(coefficients: CoefficientsDTO): CoefficientsModel {
        const { phrtf_capture_metadata: metadata } = coefficients;
        return {
            backendVersion: coefficients.backend_version,
            downloadUrl: coefficients.downloadUrl,
            metadata: {
                captureSubmissionDate: new Date(
                    metadata.capture_submission_date,
                ).toLocaleString(),
                captureType: metadata.capture_type,
                label: metadata.label,
                name: metadata.name,
                phrtfAlgorithmVersion: metadata.phrtf_algorithm_version,
                phrtfCreationDate: new Date(
                    metadata.phrtf_creation_date,
                ).toLocaleString(),
                uuid: metadata.uuid,
                version: metadata.version,
            },
        };
    }
}
