import { useActivateUser } from '../../api/hooks/useActivateUser';
import { useSendVerificationEmail } from '../../api/hooks/useSendVerificationEmail';
import { Spinner } from '../Spinner/Spinner';
import useTranslate from '../../translation/i18n';
import { useNavigate } from 'react-router';
import { API_ERROR_KEYS, getErrorTranslation } from '../../api/utils/errorKeys';
import useChangeTitle from '../../api/hooks/useChangeTitle';
import { useSearchParams } from 'react-router-dom';

export const UserActivationStatus = () => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslate();

    const code = searchParams.get('code');
    const userId = searchParams.get('user_id');
    if (!code || !userId)
        throw new Error(t('verification.description.main.negative.invalid'));

    const { error, isLoading } = useActivateUser(userId, code);

    if (isLoading) return <Spinner />;

    if (error) {
        return (
            <UserActivationFailed
                userId={userId}
                errorKeys={error.response?.data.errorKeys}
            />
        );
    }

    return <UserActivationSuccess />;
};

export const UserActivationFailed = ({
    userId,
    errorKeys,
}: {
    userId: string;
    errorKeys?: string;
}) => {
    const navigate = useNavigate();
    const { t } = useTranslate();

    const title = t('verification.title.negative');
    useChangeTitle(title);

    const { sendVerificationEmail, isLoading, error } =
        useSendVerificationEmail();

    function resendVerificationEmail() {
        sendVerificationEmail(userId, {
            onSuccess: () => {
                navigate('/renderer/verification-email-resent');
            },
        });
    }

    if (isLoading) return <Spinner />;

    return (
        <div
            id="verification-status-failed"
            className="verification-status-failed"
        >
            <div
                id="verification-status-overlay"
                className="verification-status-overlay"
            >
                <div className="row icon">
                    <img
                        alt="status-failed"
                        data-testid="error-image"
                        src="/images/status/issue.png"
                    />
                </div>
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <span>
                        {errorKeys
                            ? t(getErrorTranslation(errorKeys))
                            : t('verification.description.error.footer')}
                    </span>
                </div>
                {errorKeys !== API_ERROR_KEYS.activate_user.user_not_exists && (
                    <div className="sticky_bottom">
                        {error && (
                            <div className={'verify-address-error-layout'}>
                                <span
                                    className="error"
                                    id="verify-address-error "
                                >
                                    {t('verification.error')}
                                </span>
                            </div>
                        )}
                        <div className="pure-controls">
                            <button
                                id="resend-verification-email"
                                data-testid="resend-verification-email-button"
                                onClick={resendVerificationEmail}
                            >
                                {t('verification.button.resend')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export const UserActivationSuccess = () => {
    const { t } = useTranslate();

    const title = t('verification.title.positive');
    useChangeTitle(title);

    return (
        <div
            id="verification-status-success"
            className="verification-status-success"
        >
            <div
                id="verification-status-overlay"
                className="verification-status-overlay"
            >
                <div className="row icon">
                    <img
                        alt="status-ok"
                        data-testid="success-image"
                        src="/images/status/ok.png"
                    />
                </div>
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <span>{t('activation.description.additional')}</span>
                    <br />
                    <span>{t('activation.description.close_window')}</span>
                </div>
            </div>
        </div>
    );
};
