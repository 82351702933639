import { useEffect } from 'react';
import useTranslate from '../../translation/i18n';
import { NavigationBar } from '../NavigationBar/NavigationBar';

const FILE_EXTENSION = '.personalized_headphone';
const DEFAULT_NAME = '';
const DOWNLOAD_DELAY_MS = 150;

export function slugify(s: string) {
    return s
        .trim()
        .replace(/[^\w\p{L}]/gu, '_')
        .toLowerCase();
}

export function generateFilename(name: string | null) {
    if (name == null) {
        name = DEFAULT_NAME;
    }
    return `${slugify(name)}${FILE_EXTENSION}`;
}

export function createLinkElement(
    url: string,
    filename: string,
    clickHandler: () => void,
) {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    a.addEventListener('click', clickHandler, { once: true });
    return a;
}

function downloadFile(url: string, filename: string) {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => saveBlobToFile(blob, filename));
}

function saveBlobToFile(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, DOWNLOAD_DELAY_MS);
    };
    console.log(url, filename);
    console.log(clickHandler);
    const a = createLinkElement(url, filename, clickHandler);
    a.click();
    return a;
}

type Props = {
    downloadUrl: string;
    fileName: string;
    email: string;
};
export function Confirmation({ downloadUrl, fileName, email }: Props) {
    const { t } = useTranslate();

    const downloadLinkTitle = `${slugify(email)}${FILE_EXTENSION}`;

    useEffect(() => {
        downloadFile(downloadUrl, generateFilename(fileName));
    }, [downloadUrl, fileName]);

    return (
        <div className="details">
            <NavigationBar />
            <div id="confirmation" className="confirmation">
                <div className="content">
                    <div className="row title">
                        <h1>{t('phrtf.downloading.title.positive')}</h1>
                    </div>
                    <div className="row">
                        <img
                            src="/images/status/ok.png"
                            data-testid="success-image"
                            alt="status ok"
                        />
                    </div>
                    <div className="row download-manually">
                        <span>
                            {t('phrtf.downloading.description.positive')}
                        </span>
                        <span>
                            <a id="manual-download" download href={downloadUrl}>
                                {downloadLinkTitle}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
