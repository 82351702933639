import log from 'loglevel';
import { COMMIT_SHA, COMMIT_TAG, VERSION } from './buildInfo';
import { getApiStageFromQuery, isProd } from './config';

export default function logConfig() {
    log.enableAll();
    log.info(`[CONFIG] Version: ${VERSION}`);

    if (isProd()) {
        log.disableAll();
    } else {
        const { oktaTenantName, prettyName } = getApiStageFromQuery();
        const deploymentStage = process.env.REACT_APP_DEPLOYMENT_ENV;
        const nodeEnv = process.env.NODE_ENV;

        log.info(`[CONFIG] OKTA Tenant is ${oktaTenantName}`);
        log.info(`[CONFIG] Deployment stage: ${deploymentStage}`);
        log.info(`[CONFIG] Commit SHA: ${COMMIT_SHA}`);
        if (COMMIT_TAG) log.info(`[CONFIG] Commit tag: ${COMMIT_TAG}`);
        log.info(`[CONFIG] Backend stage: ${prettyName}`);
        log.info(`[CONFIG] Node env: ${nodeEnv}`);
    }
}
