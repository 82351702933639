import { Link } from 'react-router-dom';
import useTranslate from '../../translation/i18n';
import { LoginProps } from './LoginContainer';

export default function LoginForm({
    emailError,
    passwordError,
    emailRef,
    passwordRef,
    handleEmailOnFocus,
    handlePasswordOnFocus,
    handleOnEnterKey,
    loginError,
    handleSignInButtonOnClick,
    sessionExpired,
    device,
}: LoginProps) {
    const { t } = useTranslate();

    return (
        <div id="static-signin-form">
            <div className="pure-control-group">
                <div className="input-wrapper">
                    <input
                        id="email"
                        name="email"
                        type="text"
                        title={t('login.email.input.error')}
                        autoComplete="email"
                        required
                        ref={emailRef}
                        onBlur={handleEmailOnFocus}
                        onKeyDown={handleOnEnterKey}
                        data-testid="email-input"
                        className={emailError ? 'error_input' : undefined}
                    />
                    <label
                        htmlFor="email"
                        className={emailError ? 'label-error' : undefined}
                    >{`${t('login.email.input.placeholder')} *`}</label>
                </div>
            </div>
            {emailError && (
                <div className="email-invalid-layout">
                    <span className="error" id="invalid-email">
                        {t('login.email.input.error')}
                    </span>
                </div>
            )}
            <div className="pure-control-group" id="password-control-group">
                <div className="input-wrapper">
                    <input
                        id="password"
                        name="password"
                        type="password"
                        title={t('login.password.input.error')}
                        required
                        ref={passwordRef}
                        onBlur={handlePasswordOnFocus}
                        onKeyDown={handleOnEnterKey}
                        data-testid="password-input"
                        className={passwordError ? 'error_input' : undefined}
                    />
                    <label
                        htmlFor="password"
                        className={passwordError ? 'label-error' : undefined}
                    >{`${t('login.password.input.placeholder')} *`}</label>
                </div>
            </div>
            {passwordError && (
                <div className="password-invalid-layout">
                    <span className="error" id="invalid-password">
                        {t('login.password.input.error')}
                    </span>
                </div>
            )}
            <div className="recover-password">
                <Link
                    id="recover-password"
                    to="/renderer/forgot-password"
                    state={{ fromLogin: device ? false : true }}
                >
                    {t('login.forgot_password.link')}
                </Link>
            </div>
            <div className="signin-invalid-layout" id="invalid-signin">
                {loginError && (
                    <span className="error">{t('login.error.message')}</span>
                )}
                {sessionExpired && (
                    <span className="error">
                        {t('login.error.session_expired')}
                    </span>
                )}
            </div>
            <div className="pure-controls">
                <button
                    id="sign-in"
                    onClick={handleSignInButtonOnClick}
                    data-testid="sign-in-button"
                >
                    {t('login.sign_in.button')}
                </button>
            </div>
        </div>
    );
}
