export const API_ERROR_KEYS = {
    activate_user: {
        invalid_token: 'invalid_activation_token',
        expired_token: 'expired_activation_token',
        user_not_exists: 'okta_user_not_exists',
    },
    verify_device_code: {
        expired_token: 'expired_token',
        device_not_exists: 'device_not_exists',
        input_validation_error_message: 'input_validation_error_message',
    },
    issue: {
        capture_not_exists: 'capture_not_exists',
        email_integrity_error: 'email_integrity_error',
    },
    internal_error: 'internal_error',
};

export function getErrorTranslation(errorKey: string, message?: string) {
    switch (errorKey) {
        case API_ERROR_KEYS.activate_user.invalid_token:
            return 'verification.description.main.negative.invalid';
        case API_ERROR_KEYS.activate_user.expired_token:
            return 'verification.description.main.negative.expired';
        case API_ERROR_KEYS.activate_user.user_not_exists:
            return 'verification.description.main.negative.doesnt_exist';

        case API_ERROR_KEYS.verify_device_code.expired_token:
            return 'activation.error.code_expired';
        case API_ERROR_KEYS.verify_device_code.device_not_exists:
            return 'activation.error.code_doesnt_exist';
        case API_ERROR_KEYS.verify_device_code.input_validation_error_message:
            return 'activation.error.code_doesnt_exist';

        case API_ERROR_KEYS.issue.capture_not_exists:
            return 'issue.no_capture.description';
        case API_ERROR_KEYS.issue.email_integrity_error:
            return 'issue.email_integrity_error';

        default:
            return message ?? errorKey;
    }
}
