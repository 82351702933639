import { AxiosError, AxiosResponse } from 'axios';
import { api } from '../utils/axios';
import { useQuery } from '@tanstack/react-query';
import log from 'loglevel';
import { errorKeyCatcher } from '../../queryClient';

export const useActivateUser = (userId: string, code: string) => {
    const { isLoading, error } = useQuery<
        AxiosResponse,
        AxiosError<{ errorKeys: string }>
    >(
        ['activateUser', userId, code],
        async () => {
            const response = await api.put(
                `/users/${userId}/activate`,
                {},
                {
                    params: { code },
                },
            );

            log.info('Activate user response: ');
            log.info(response);
            return response;
        },
        {
            useErrorBoundary: errorKeyCatcher,
        },
    );
    return { isLoading, error };
};
