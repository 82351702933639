import useChangeTitle from '../../api/hooks/useChangeTitle';
import useTranslate from '../../translation/i18n';
import { LoginProps } from './LoginContainer';
import LoginForm from './LoginForm';

export default function DeviceLogin(loginProps: LoginProps) {
    const { t } = useTranslate();

    useChangeTitle(t('short_login.title'));

    return (
        <div id="login-okta" className="login-okta">
            <div id="login-okta-overlay" className="login-okta-overlay">
                <div className="row">
                    <img
                        data-testid="dolby-logo"
                        className="logo"
                        alt="logo"
                        src="/images/logos/dolby.png"
                    />
                </div>
                <div className="row short-title">
                    <h1>{t('short_login.title')}</h1>
                </div>
                <LoginForm {...loginProps} />
            </div>
        </div>
    );
}
