import { CustomerTypes, UserProfiles } from './telemetryConstants';

export function getCustomerType(userProfile: string): CustomerTypes {
    switch (userProfile) {
        case UserProfiles.CONSUMER:
            return CustomerTypes.CONSUMER;
        case UserProfiles.PROF_MUSICIAN:
            return CustomerTypes.MUSIC;
        case UserProfiles.PROF_GAMER:
            return CustomerTypes.GAMING;
        default:
            return CustomerTypes.CONSUMER;
    }
}
