import useTranslate from '../../translation/i18n';
import useChangeTitle from '../../api/hooks/useChangeTitle';

export const UserActivation = () => {
    const { t } = useTranslate();

    const title = t('verification.title.initial');
    useChangeTitle(title);

    return (
        <div
            id="verify-your-address"
            className="verify-your-address not-displayed"
        >
            <div
                id="verify-your-address-overlay"
                className="verify-your-address-overlay"
            >
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <p>{t('verification.description.main.initial')}</p>
                </div>
                <div className="verify-address-error-layout">
                    <span className="error" id="verify-address-error">
                        {t('verification.error')}
                    </span>
                </div>
                <div>{t('verification.error.instructions')}</div>
            </div>
        </div>
    );
};
