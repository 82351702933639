import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isProd } from '../config/config';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['navigator'],
            caches: [], // disable language persisting (for ex localStorage)
        },
        backend: {
            loadPath: '/locales/language_{{lng}}.json',
        },
        fallbackLng: 'en',
        debug: isProd() ? false : true,
    });

export default function useTranslate() {
    return useTranslation('translation', { keyPrefix: 'webapp' });
}
