import useTranslate from '../../translation/i18n';
import { LoginProps } from './LoginContainer';
import LoginForm from './LoginForm';
import useChangeTitle from '../../api/hooks/useChangeTitle';

export default function Login(loginProps: LoginProps) {
    const { t } = useTranslate();

    useChangeTitle(t('login.title'));

    return (
        <div id="login-okta" className="login-okta">
            <div id="login-okta-overlay" className="login-okta-overlay">
                <div className="row">
                    <img
                        data-testid="dolby-logo"
                        className="logo"
                        alt="logo"
                        src="/images/logos/dolby.png"
                    />
                </div>
                <div className="row title">{t('login.description')}</div>
                <LoginForm {...loginProps} />
                <div id="get-phrtf-file-block">
                    <a
                        href="https://professional.dolby.com/phrtf"
                        id="get-yours-here"
                    >
                        {t('login.file.link')}
                    </a>
                </div>
            </div>
        </div>
    );
}
