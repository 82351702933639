import { CoefficientsModel } from '../../api/models/coefficients';
import { NavigationBar } from '../NavigationBar/NavigationBar';
import useTranslate from '../../translation/i18n';
import { useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

type Props = {
    data: CoefficientsModel;
    email: string;
    name: string;
    accessToken: string;
    handleDownload: (e: React.MouseEvent<HTMLElement>) => void;
};

export function Details({
    data,
    email,
    name,
    accessToken,
    handleDownload,
}: Props) {
    const { t } = useTranslate();
    const [searchParams] = useSearchParams();
    const debug = searchParams.get('debug');

    let decodedJwt;
    if (debug)
        decodedJwt = jwtDecode<{ aud: string; iss: string }>(accessToken);

    return (
        <div className="details">
            <NavigationBar />

            <div id="file-details" className="file-details">
                <h1 className="title">{t('phrtf.details.title')}</h1>
                <img
                    src="/images/misc/headphones.png"
                    data-testid="headphones-image"
                    alt="headphones-icon"
                />
                <div className="file-data">
                    <div>
                        <div className="row">
                            <h6 id="user_name">{data.metadata.name}</h6>
                        </div>
                        <div className="row separation-small">
                            <span id="phrtf_label">{data.metadata.label}</span>
                        </div>
                        <div className="row separation-mid">
                            <span id="user_email">{email}</span>
                        </div>
                        <div className="row separation-small">
                            <span className="secondary-text label">
                                {t('phrtf.details.description.capture_date')}
                            </span>
                            <span id="capture_timestamp">
                                {data.metadata.captureSubmissionDate}
                            </span>
                        </div>
                        <div className="row separation-small">
                            <span className="secondary-text label">
                                {t('phrtf.details.description.create_date')}
                            </span>
                            <span id="phrtf_creation_date">
                                {data.metadata.phrtfCreationDate}
                            </span>
                        </div>
                        <div className="row separation-small">
                            <span className="secondary-text label">
                                {t('phrtf.details.description.capture_method')}
                            </span>
                            <span id="capture_method">
                                {data.metadata.captureType}
                            </span>
                        </div>
                        <div className="row separation-small">
                            <span className="secondary-text label">
                                {t(
                                    'phrtf.details.description.algorithm_version',
                                )}
                            </span>
                            <span id="suez_version">
                                {data.metadata.phrtfAlgorithmVersion}
                            </span>
                        </div>

                        {decodedJwt && (
                            <>
                                <div className="row separation-small">
                                    <span className="secondary-text label">
                                        Sign In Provider
                                    </span>
                                    <span id="provider_id">Okta</span>
                                </div>
                                <div className="row separation-small">
                                    <span className="secondary-text label">
                                        DisplayName
                                    </span>
                                    <span id="display_name">{name}</span>
                                </div>
                                <div className="row separation-small">
                                    <span className="secondary-text label">
                                        Claims: Audience
                                    </span>
                                    <span id="claims_audience">
                                        {decodedJwt.aud}
                                    </span>
                                </div>
                                <div className="row separation-small">
                                    <span className="secondary-text label">
                                        Claims: Issuer
                                    </span>
                                    <span id="claims_issuer">
                                        {decodedJwt.iss}
                                    </span>
                                </div>

                                <div id="metadata-container">
                                    {Object.entries(data.metadata).map(
                                        ([key, value]) => (
                                            <div
                                                key={key}
                                                id="metadata-template"
                                                className="row separation-small"
                                            >
                                                <span className="secondary-text label metadata-key">
                                                    {key}
                                                </span>
                                                <span className="metadata-value">
                                                    {value}
                                                </span>
                                            </div>
                                        ),
                                    )}
                                </div>
                            </>
                        )}

                        <div className="row">
                            <div className="col-12" id="phrtf_download_link">
                                <button
                                    id="btn-download"
                                    className="primary-button"
                                    data-testid="download-button"
                                    onClick={handleDownload}
                                >
                                    {t('phrtf.details.button')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
